<template>
  <div class="main-container">
    <Header />

    <TopCarousel :sliders="slider.PayForU" />

    <AboutSectionFour :data="payforu.about" :title="'¿Qué es PayForU?'" />

    <div
      class="breadcrumb-area bg-img"
      style="
        background-image: url(assets/img/banners/payforu/f1.jpg);
        padding: 20px;
        width: -webkit-fill-available;
      "
    >
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="page-banner text-center">
              <div class="feature-icon-wrapper">
                <div class="row">
                  <div class="col-lg-3 col-md-6">
                    <div class="single-feature-icon">
                      <div
                        class="single-feature-icon__image"
                        style="text-align: center; color: #fff"
                      >
                        <img
                          src="/assets/img/icons/payforu/i1-1.png"
                          alt="icon"
                          class="img-fluid"
                        />
                      </div>
                      <h3
                        class="single-feature-icon__title"
                        style="text-align: center; color: #fff"
                      >
                        Multipago
                      </h3>
                      <p
                        class="single-feature-icon__content"
                        style="text-align: center; color: #fff"
                      >
                        Utiliza distintas pasarelas tanto en moneda nacional como
                        extranjera.
                      </p>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-6">
                    <div class="single-feature-icon">
                      <div
                        class="single-feature-icon__image"
                        style="text-align: center; color: #fff"
                      >
                        <img
                          src="/assets/img/icons/payforu/i2-2.png"
                          alt="icon"
                          class="img-fluid"
                        />
                      </div>
                      <h3
                        class="single-feature-icon__title"
                        style="text-align: center; color: #fff"
                      >
                        Versátil
                      </h3>
                      <p
                        class="single-feature-icon__content"
                        style="text-align: center; color: #fff"
                      >
                        Aporta una suite de herramientas tecnológicas.
                      </p>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-6">
                    <div class="single-feature-icon">
                      <div
                        class="single-feature-icon__image"
                        style="text-align: center; color: #fff"
                      >
                        <img
                          src="/assets/img/icons/payforu/i33.png"
                          alt="icon"
                          class="img-fluid"
                        />
                      </div>
                      <h3
                        class="single-feature-icon__title"
                        style="text-align: center; color: #fff"
                      >
                        Segura
                      </h3>
                      <p
                        class="single-feature-icon__content"
                        style="text-align: center; color: #fff"
                      >
                        Protege las transacciones adaptándose a cada necesidad.
                      </p>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-6">
                    <div class="single-feature-icon">
                      <div
                        class="single-feature-icon__image"
                        style="text-align: center; color: #fff"
                      >
                        <img
                          src="/assets/img/icons/payforu/i44.png"
                          alt="icon"
                          class="img-fluid"
                        />
                      </div>
                      <h3
                        class="single-feature-icon__title"
                        style="text-align: center; color: #fff"
                      >
                        Estructurada
                      </h3>
                      <p
                        class="single-feature-icon__content"
                        style="text-align: center; color: #fff"
                      >
                        Cubre todos los procesos de cumplimiento exigidos para el
                        procesamiento de pagos electrónicos.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Uses and Implementatios section-->
    <ProjectGridSlider
      :data="payforu.owrClients"
      :title="'Nuestros Clientes'"
      :height="'height200'"
    />

    <div>
      <img
        src="/assets/img/banners/payforu/9.jpg"
        style="background-size: cover; width: 100%"
      />
    </div>

    <!--<LatestProject :data="payforu.owrClients" :title="'Nuestros Clientes'" />-->

    <!--<TeamMember
      :teamData="likeucreative.clients"
      :title="'Nuestros Servicios'"
    />-->

    <!--<h2 style="padding-top:20px;font-size: 30px;" class="text-center">
      Conoce más
    </h2>-->

    <ProjectGridSlider
      :data="payforu.implementations"
      :title="'Usos e Implementaciones'"
    />

    <div class="about-section section-space--inner--60">
      <div class="container">
        <div class="row row-25 align-items-center">
          <h2 class="text-center" style="padding-bottom: 30px">Ventajas competitivas</h2>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="team-job__content-wrapper">
              <div class="team-job__list-wrapper">
                <div class="team-job__single">
                  <h3 class="title">Estándares de confiabilidad</h3>
                  <p class="text" style="text-align: justify">
                    Herramienta versátil, segura y estructurada para cubrir todos los
                    procesos de cumplimiento exigidos para el procesamiento de pagos
                    electrónicos.
                  </p>
                </div>
                <div class="team-job__single">
                  <h3 class="title">Facilidad y calidad de servicio</h3>
                  <p class="text" style="text-align: justify">
                    Se adapta a la mayoría de los consumidores, ya que cuentan con la
                    posibilidad de pagar con distintos instrumentos financieros desde
                    cualquier parte del mundo.
                  </p>
                </div>
                <div class="team-job__single">
                  <h3 class="title">Autogestionable</h3>
                  <p class="text" style="text-align: justify">
                    Cuenta con su propio backoffice administrativo a través de acceso
                    personalizado para cada cliente, permitiendo el el control y
                    seguimiento de cada transacción.
                  </p>
                </div>
              </div>
            </div>
            <div class="team-job__content-wrapper">
              <div class="team-job__list-wrapper">
                <div class="team-job__single">
                  <h3 class="title">De versátil implementación</h3>
                  <p class="text" style="text-align: justify">
                    Incorpora de forma fácil , rápida y confiable un botón de pago
                    configurado para cada necesidad, ahorrando así tiempo y dinero en
                    implementaciones.
                  </p>
                </div>
                <div class="team-job__single">
                  <h3 class="title">Utilidad integrada</h3>
                  <p class="text" style="text-align: justify">
                    PayForU se integra fácilmente en APP’s y páginas web , permitiendo el
                    manejo propio de liquidaciones a conveniencia de cada negocio.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <img
                src="/assets/img/banners/payforu/1.png"
                style="background-size: cover; width: 100%"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <img
        src="/assets/img/banners/payforu/2.png"
        style="background-size: cover; width: 100%"
      />
    </div>

    <div class="col-12">
      <h2 class="text-center" style="padding-top: 30px">
        Recibe pagos a través de PayForU
      </h2>
      <div
        class="breadcrumb-area bg-img"
        style="
          background-image: url(assets/img/banners/payforu/f2.jpg);
          background-size: cover;
          width: 100%;
        "
      >
        <div class="container">
          <h3 style="padding: 0 20%; text-align: center">
            Regístrate y afilia uno de los planes PayForU que se ajuste a tu negocio y
            comienza a ofrecer a tus clientes diferentes medios de pagos.
          </h3>
          <div class="row text-center">
            <div style="margin: auto">
              <img
                style="width: 100%; padding-top: 30px"
                src="/assets/img/\banners/payforu/pasos.png"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <CarouselPlanSection
      :title="'Planes de Afiliación'"
      :subtitle="'PayForU funciona exclusivamente para las aplicaciones de nuestro Ecosistema Digital. Si deseas utilizar PayForU como botón de pago para tu negocio. Escríbenos a través del Formulario que encontrarás al final de esta página y con gusto te daremos más información.'"
      :platform="2"
      :plse_type="'payment'"
    />

    <div
      class="breadcrumb-area bg-img"
      style="
        background-color: #000;
        width: -webkit-fill-available;
        height: auto;
        padding: 30px;
      "
    >
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h2 class="text-center" style="color: #fff">Esquema de comisiones</h2>
            <br />
            <p class="text-center" style="color: #fff; font-size: 20px">
              PayForU cobra comisiones estandar por transaccion segun el metodo de pago y
              la pasarela que se utilice.
            </p>
            <p class="text-center" style="color: #fff; font-size: 20px">
              Comisiones competititvas para mantener tu negocio.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div
      class="breadcrumb-area bg-img"
      style="
        background-image: url(assets/img/banners/payforu/f3.jpg);
        width: -webkit-fill-available;
        height: auto;
        padding: 30px;
      "
    >
      <div class="container">
        <div class="row">
          <div class="col-8">
            <h2 class="text-center" style="color: #fff">
              Recibe pagos a través de PayForU
            </h2>
          </div>
          <div class="col-4">
            <a href="https://payfor-u.com">
              <button class="btn ht-btn--round" style="background-color: #003d74">
                Empezar
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>

    <!--<div>
      <img
        src="/assets/img/banners/payforu/perguntas_frecuentes.jpg"
        style="background-size: cover;"
      />
    </div>-->

    <div class="about-section section-space--inner--60" id="contact-form">
      <div class="container">
        <div class="row row-25">
          <div class="col-12">
            <h2 class="subtitle text-center" style="font-weight: bold">
              Preguntas frecuentes
            </h2>
          </div>
          <div class="col-lg-6 col-12 mt--30" style="padding-top: 20px">
            <div v-for="item in payforu.FAQ" :key="item.id">
              <Accordion :question="item.question" :answer="item.answer" />
            </div>
          </div>
          <div class="col-lg-6 col-12 mb-30">
            <ContactForm :platform="2" />
          </div>
        </div>
      </div>
    </div>

    <div class="about-section section-space--inner--20">
      <div class="container">
        <div class="row row-25">
          <div class="col-12">
            <h2 class="subtitle text-center" style="font-weight: bold">
              Conoce nuestro Ecosistema Digital
            </h2>
          </div>
        </div>
      </div>
    </div>

    <Carousel2 :data="slider.ecosystem" />

    <BrandCarousel addClass="grey-bg" />

    <Footer />

    <OffCanvasMobileMenu />

    <!-- WhatsApp Bottom -->
    <div id="whatsapp">
      <a
        href="https://api.whatsapp.com/send?phone=+584142698744&text=¡Hola!%20Quiero%20más%20información%20acerca%20de%20sus%20servicios."
        title="Escríbenos por Whastapp"
        data-tap="tooltip"
      >
        <i class="fa fa-whatsapp" style="color: #ffffff" aria-hidden="true"></i>
      </a>
    </div>

    <!-- back to top start -->
    <back-to-top class="scroll-top" bottom="100px" right="10px">
      <i class="ion-android-arrow-up"></i>
    </back-to-top>
    <!-- back to top end -->
  </div>
</template>

<script>
import slider from "../data/slider.json";
import data from "../data/service.json";
import payforu from "../data/payforu.json";
import Header from "@/components/Header";
import AboutSectionFour from "../components/sections/AboutSectionFour";
import HeroTwo from "../components/sections/HeroTwo";
import TopCarousel from "../components/TopCarousel";
import Carousel1 from "../components/sections/Carousel1";
import Carousel2 from "../components/sections/Carousel2";
import ProjectGridSlider from "@/components/sections/ProjectGridSlider";
import Breadcrumb from "../components/Breadcrumb";
import TeamMember from "../components/sections/TeamMember";
import LatestProject from "../components/sections/LatestProject";
import Accordion from "../components/Accordion";
import ContactForm from "../components/ContactForm";
import BrandCarousel from "../components/BrandCarousel";
import Footer from "../components/Footer";
import OffCanvasMobileMenu from "@/components/OffCanvasMobileMenu";
import ServiceDetailsSidebar from "@/components/ServiceDetailsSidebar";
import ServiceDetailsMain from "@/components/ServiceDetailsMain";
import CarouselPlanSection from "@/components/sections/CarouselPlanSection";

export default {
  components: {
    Header,
    HeroTwo,
    LatestProject,
    Breadcrumb,
    TopCarousel,
    Accordion,
    ContactForm,
    ServiceDetailsMain,
    ProjectGridSlider,
    TeamMember,
    AboutSectionFour,
    ServiceDetailsSidebar,
    Carousel1,
    Carousel2,
    BrandCarousel,
    Footer,
    OffCanvasMobileMenu,
    CarouselPlanSection,
  },
  data() {
    return {
      config: {
        headers: {
          Authorization: "",
        },
      },
      data,
      slider,
      payforu,
      orderData: "",
      user_id: "",
    };
  },
  beforeCreate() {
    if (sessionStorage.getItem("country") === null) {
      sessionStorage.setItem("country", "057-venezuela");
    }
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.breadcrumb-area {
  min-height: 0px;
}

.swiper-wrapper {
  padding: 0px !important;
}

.login-form {
  min-height: 200px;
  align-items: center;
  justify-content: center;
}

.ht-btn--round {
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: -10%;
  font-size: 20px;
  position: relative;
  text-align: center;
  margin: auto;
  display: inline-flex;
  align-items: center;
  vertical-align: bottom !important;
  background-color: #e94e1b;
}

.breadcrumb-area::before {
  min-height: 0px;
  background-color: #00000000;
}

#whatsapp {
  cursor: pointer;
  position: fixed;
  right: 10px;
  bottom: 20px;
  display: block;
  color: #ffffff;
  z-index: 1000;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
  font-size: 25px;
  line-height: 60px;
  background-color: #00e676;
}

@media only screen and (max-width: 479px) {
  .scroll-top {
    right: 10px !important;
    bottom: 80px !important;
  }

  #whatsapp {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #202020;
  font-weight: 600;
  line-height: 35px !important;
}
</style>
