<template>
	<!--====================  project slider area ====================-->
	<div class="section-space--inner--60">
		<div
			class="project-slider-area"
			style="padding-top: 10px; width: -webkit-fill-available"
		>
			<div class="row">
				<div class="col-12">
					<h2 class="subtitle text-center" style="font-weight: bold">
						{{ title }}
					</h2>
					<h4 class="subtitle text-center" style="padding: 20px 10%">
						{{ subtitle }}
					</h4>
				</div>
			</div>

			<div class="row">
				<div class="container">
					<div class="row" v-if="servicesData.length > 1">
						<div class="col-12 text-center">
							<label for="">{{ selectorText }}</label>
							<select
								style="width: 300px; margin: auto"
								class="form-control"
								v-model="mainService"
								@change="setMainService(mainService)"
							>
								<option
									v-for="service in servicesData"
									:key="service.id"
									:value="service"
								>
									{{ service.serv_name }}
								</option>
							</select>
						</div>
					</div>
					<div v-if="mainService != ''" class="row" style="padding-bottom: 20px">
						<h3 class="subtitle text-center" style="padding: 20px"></h3>
						<swiper :options="swiperOption">
							<div
								style="height: auto"
								class="swiper-slide service-grid-item--style2"
								v-for="item in serviceData.sub_services_plan"
								:key="item.id"
							>
								<div class="login-form" style="height: 100%">
									<div
										style="
											min-height: 100px;
											background-color: #003d74;
											color: #fff;
											padding: 5%;
											display: flex;
											align-items: center;
										"
									>
										<h4
											class="login-title text-center"
											style="color: #fff; margin: auto; padding-bottom: 0px"
										>
											{{ item.serv_name | toUpperCaseFormat }}
										</h4>
									</div>
									<div style="min-height: 150px">
										<h5 style="padding: 5%">
											<span>{{ item.serv_generalinformation }}</span>
										</h5>
									</div>
									<div>
										<h5 style="min-height: 0px"></h5>
									</div>

									<!--<div class="" style="bottom: 20px">
                  <div v-show="item.sub_services_additional != ''">
                    <table class="table" style="vertical-align: middle">
                      <tbody>
                        <tr
                          style="border-top: 1px solid #dee2e6"
                          v-for="extra in item.sub_services_additional"
                          :key="extra.id"
                        >
                          <td style="width: 5%; padding-left: 0px; padding-right: 0px">
                            <div class="check-box">
                              <input
                                type="checkbox"
                                :id="'check' + item.id"
                                @click="setAdditionalCost(item.id, extra)"
                                style="display: inline-block"
                              />
                            </div>
                          </td>
                          <td style="width: 200px">
                            {{ extra.serv_name }}
                          </td>
                          <td style="float: right; position: absolute">
                            {{ extra.currency.curr_symbol
                            }}<input
                              style="text-align: right"
                              type="text"
                              readonly
                              :id="'additional' + item.id"
                              :value="extra.serv_unitcost"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>-->

									<!--<table class="table" style="vertical-align: middle">
                    <tbody>
                      <tr
                        v-if="item.serv_unitcost > 0"
                        style="border-top: 1px solid #dee2e6"
                      >
                        <td style="width: 70%">Período de afiliación (Meses)</td>
                        <td style="float: right; position: absolute">
                          <div class="input-group" style="flex-wrap: initial">
                            <span class="input-group-btn">
                              <button
                                @click.prevent="subtract(item.id)"
                                type="button"
                                class="qty-change"
                              >
                                -
                              </button>
                            </span>
                            <input
                              :id="'servicio' + item.id"
                              oninput="if(this.value > 24){this.value = 24;}"
                              type="number"
                              required
                              min="1"
                              max="24"
                              v-model="period"
                              style="padding: 0px; width: 60%"
                              class="qty-input form-group"
                              readonly
                              v-on:input="/*period = $event.target.value*/"
                              @change="/*validatePeriod*/"
                            />
                            <span class="input-group-btn">
                              <button
                                @click.prevent="add(item.id)"
                                type="button"
                                class="qty-change"
                              >
                                +
                              </button>
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr
                        v-if="item.serv_unitcost > 0"
                        style="border-top: 1px solid #dee2e6"
                      >
                        <td>Precio por mes</td>
                        <td style="float: right; position: absolute">
                          {{ item.currency.curr_symbol
                          }}<input
                            style="text-align: right"
                            type="text"
                            :id="'unitCost' + item.id"
                            :value="item.serv_unitcost"
                            readonly
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>-->

									<div class="row" style="bottom: 20px">
										<div class="" style="bottom: 20px; margin: auto">
											<!--<h1 class="text-center" v-if="item.serv_unitcost > 0">
                      {{ item.currency.curr_symbol }}
                      {{ item.serv_unitcost }}
                    </h1>-->

											<div class="card-carousel--card--footer" style="padding-top: 20px">
												<div style="text-align: center">
													<button
														type="submit"
														@click="orderGenerate(item)"
														class="btn ht-btn--round"
													>
														Afiliar
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</swiper>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!--====================  End of project slider area  ====================-->
</template>

<script>
	import payforu from '../../data/payforu.json'
	import axios from 'axios'
	export default {
		props: ['title', 'subtitle', 'selectorText', 'platform', 'plse_type'],
		data() {
			return {
				payforu,
				swiperOption: {
					speed: 6000,
					loop: false,
					slideChange: 5000,
					delay: 5000,
					autoplay: {
						delay: 3000,
						disableOnInteraction: true,
					},
					spaceBetween: 5,
					navigation: {
						nextEl: '.ht-swiper-button-next',
						prevEl: '.ht-swiper-button-prev',
					},
					breakpoints: {
						1499: {
							slidesPerView: 3,
						},

						768: {
							slidesPerView: 3,
						},

						480: {
							slidesPerView: 1,
						},
					},
				},
				config: {
					headers: {
						Authorization: '',
					},
				},
				servicesData: [],
				serviceData: [],
				mainService: '',
				subServices: [],
				period: 1,
				servAdditional: '',
				additionalCost: 0,
				unitcost: '',
				total: '',
			}
		},
		computed: {
			setPeriod() {
				return this.period
			},
			totalAmount() {
				this.total = (
					parseFloat(this.unitcost) * parseFloat(this.quantity) +
					this.additionalCost
				).toFixed(2)
				let total = this.total
				return total
			},
		},
		filters: {
			toUpperCaseFormat: function (value) {
				if (!value) return ''
				let response = value.toUpperCase()
				return response
			},
			toNumberFormat: function (value) {
				if (!value) return ''
				let response = new Intl.NumberFormat('en-US', {
					style: 'currency',
					currency: 'USD',
				}).format(value)
				return response
			},

			toDecimalFormat: function (value) {
				if (!value) return '0.00'
				let response = parseFloat(value).toFixed(2)
				return response
			},
		},
		created() {
			this.getServiceData()
		},
		methods: {
			setMainService(mainService) {
				this.serviceData = mainService
			},
			setAdditionalCost(id, extra) {
				let totalInput = document.getElementById('total' + id)
				let checkInput = document.getElementById('check' + id)
				let additionalInput = document.getElementById('additional' + id)
				if (checkInput.checked) {
					totalInput.value = (
						parseInt(totalInput.value) + parseFloat(additionalInput.value)
					).toFixed(2)
					this.servAdditional = {
						description: extra.serv_name,
						uniqueID: extra.id,
						plat_id: this.platform,
						quantity: 1,
						price: parseFloat(extra.serv_unitcost),
					}
				} else {
					totalInput.value = (totalInput.value - additionalInput.value).toFixed(2)
					this.servAdditional = ''
				}
				console.log(this.servAdditional)
			},
			add(id) {
				let periodInput = document.getElementById('servicio' + id)
				let unitCostInput = document.getElementById('unitCost' + id)
				let totalInput = document.getElementById('total' + id)
				let checkInput = document.getElementById('check' + id)
				let additionalInput = document.getElementById('additional' + id)
				if (periodInput.value >= 24) {
					periodInput.value = 24
					if (checkInput != null && checkInput.checked) {
						totalInput.value = (
							parseFloat(periodInput.value) * parseFloat(unitCostInput.value) +
							parseFloat(additionalInput.value)
						).toFixed(2)
					} else {
						totalInput.value = (
							parseFloat(periodInput.value) * parseFloat(unitCostInput.value)
						).toFixed(2)
					}
				} else {
					periodInput.value++
					if (checkInput != null && checkInput.checked) {
						totalInput.value = (
							parseFloat(periodInput.value) * parseFloat(unitCostInput.value) +
							parseFloat(additionalInput.value)
						).toFixed(2)
					} else {
						totalInput.value = (
							parseFloat(periodInput.value) * parseFloat(unitCostInput.value)
						).toFixed(2)
					}
				}
			},
			subtract(id) {
				let periodInput = document.getElementById('servicio' + id)
				let unitCostInput = document.getElementById('unitCost' + id)
				let totalInput = document.getElementById('total' + id)
				let checkInput = document.getElementById('check' + id)
				let additionalInput = document.getElementById('additional' + id)
				if (periodInput.value <= 1) {
					periodInput.value = 1
					if (checkInput != null && checkInput.checked) {
						totalInput.value = (
							parseFloat(periodInput.value) * parseFloat(unitCostInput.value) +
							parseFloat(additionalInput.value)
						).toFixed(2)
					} else {
						totalInput.value = (
							parseFloat(periodInput.value) * parseFloat(unitCostInput.value)
						).toFixed(2)
					}
				} else {
					periodInput.value--
					if (checkInput != null && checkInput.checked) {
						totalInput.value = (
							parseFloat(periodInput.value) * parseFloat(unitCostInput.value) +
							parseFloat(additionalInput.value)
						).toFixed(2)
					} else {
						totalInput.value = (
							parseFloat(periodInput.value) * parseFloat(unitCostInput.value)
						).toFixed(2)
					}
				}
			},
			async getServiceData() {
				let urlService =
					process.env.VUE_APP_STORE_API +
					'api/platforms/' +
					this.platform +
					'/services/' +
					this.plse_type
				axios
					.get(urlService, {
						headers: {
							'Content-type': 'application/json',
							Accept: 'application/json',
						},
					})
					.then((response) => {
						console.log(response.data.services)
						this.servicesData = response.data.services
						this.mainService = response.data.services[0]
						this.serviceData = response.data.services[0]
					})
					.catch((error) => {
						this.error = true
					})
			},
			async orderGenerate(item) {
				if (sessionStorage.getItem('token') === null) {
					this.$swal({
						title: 'Espere...',
						text: 'Debe iniciar sesión antes de continuar',
						icon: 'info',
						timer: 3000,
						showCancelButton: false,
						showConfirmButton: false,
					}).then(() => {
						this.$router.push('/login')
					})
				} else {
					this.$swal({
						title: 'Espere...',
						text: 'Estamos generando su orden',
						icon: 'info',
						timer: 3000,
						showCancelButton: false,
						showConfirmButton: false,
					})
					this.$swal.showLoading()
					this.config.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token')
					var fullItems = [
						{
							description: item.serv_name,
							uniqueID: item.id,
							plat_id: this.platform,
							quantity: 1,
							price: parseFloat(item.serv_unitcost),
						},
					]
					console.log(fullItems)
					axios
						.post(
							process.env.VUE_APP_CART_API + 'api/orders',
							{
								plat_id: 12,
								currency: item.currency.curr_symbol,
								items: fullItems,
							},
							this.config
						)
						.then((response) => {
							this.orderData = response.data
							console.log(response.data)
							sessionStorage.removeItem('order')
							sessionStorage.removeItem('items')
							sessionStorage.setItem('order', JSON.stringify(response.data.order))
							sessionStorage.setItem('items', response.data.order.items.length)
							setTimeout(() => (location.href = '/carrito'), 4000)
							//this.$router.push("/carrito");
						})
						.catch((error) => {
							console.log(error.response)
							this.error = true
						})
				}
			},
		},
	}
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/main.scss';

	.ht-btn--round {
		padding: 10px;
		padding-top: 5px;
		padding-bottom: 5px;
		margin-bottom: -10%;
		font-size: 18px;
		position: relative;
		text-align: center;
		margin: auto;
		display: inline-flex;
		align-items: center;
		vertical-align: bottom !important;
		background-color: #e94e1b;
	}

	button.qty-change {
		height: 30px;
		width: 30px;
		border-radius: 50%;
		background: #fff;
		border: 1px solid #e3e3e3;
	}

	.qty-input {
		height: 30px;
		text-align: center;
	}

	.login-form input {
		width: 60px;
		background-color: transparent;
		border: 0px solid #e5e5e5;
		border-radius: 0;
		line-height: 23px;
		font-size: 14px;
		padding: 0px;
		margin-bottom: 15px;
	}

	.table td {
		padding: 0.75rem;
		vertical-align: top;
		border-top: none;
	}

	.swiper-wrapper {
		position: relative;
		padding: 10px;
		width: 100%;
		height: auto;
		margin-right: -20px !important;
		z-index: 1;
		display: flex;
		transition-property: transform;
		box-sizing: content-box;
	}

	.swiper-container {
		margin-left: -10px !important;
		margin-right: -10px !important;
		position: relative;
		overflow: hidden;
		list-style: none;
		padding: 0;
		z-index: 1;
	}

	.swiper-slide {
		flex-shrink: 0;
		width: 100%;
		height: 98%;
		padding: 5px !important;
		position: relative;
		transition-property: transform;
	}

	.service-grid-item--style2 {
		box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.2);
	}
	@media only screen and (max-width: 800px) {
		.login-form {
			padding: 15px;
		}
	}
</style>
